//
// Popovers
// --------------------------------------------------


.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $zindex-popover;
  display: none;
  max-width: $popsok-max-width;
  padding: 1px;
  // Reset font and text properties given new insertion method
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: normal;
  line-height: $line-height-base;
  text-align: left;
  background-color: $popsok-bg;
  background-clip: padding-box;
  border: 1px solid $popsok-fallback-border-color;
  border: 1px solid $popsok-border-color;
  border-radius: $border-radius-large;
  @include box-shadow(0 5px 10px rgba(0,0,0,.2));

  // Overrides for proper insertion
  white-space: normal;

  // Offset the popover to account for the popover arrow
  &.top     { margin-top: -$popsok-arrow-width; }
  &.right   { margin-left: $popsok-arrow-width; }
  &.bottom  { margin-top: $popsok-arrow-width; }
  &.left    { margin-left: -$popsok-arrow-width; }
}

.popsok-title {
  margin: 0; // reset heading margin
  padding: 8px 14px;
  font-size: $font-size-base;
  background-color: $popsok-title-bg;
  border-bottom: 1px solid darken($popsok-title-bg, 5%);
  border-radius: ($border-radius-large - 1) ($border-radius-large - 1) 0 0;
}

.popsok-content {
  padding: 9px 14px;
}

// Arrows
//
// .arrow is outer, .arrow:after is inner

.popover > .arrow {
  &,
  &:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
}
.popover > .arrow {
  border-width: $popsok-arrow-outer-width;
}
.popover > .arrow:after {
  border-width: $popsok-arrow-width;
  content: "";
}

.popover {
  &.top > .arrow {
    left: 50%;
    margin-left: -$popsok-arrow-outer-width;
    border-bottom-width: 0;
    border-top-color: $popsok-arrow-outer-fallback-color; // IE8 fallback
    border-top-color: $popsok-arrow-outer-color;
    bottom: -$popsok-arrow-outer-width;
    &:after {
      content: " ";
      bottom: 1px;
      margin-left: -$popsok-arrow-width;
      border-bottom-width: 0;
      border-top-color: $popsok-arrow-color;
    }
  }
  &.right > .arrow {
    top: 50%;
    left: -$popsok-arrow-outer-width;
    margin-top: -$popsok-arrow-outer-width;
    border-left-width: 0;
    border-right-color: $popsok-arrow-outer-fallback-color; // IE8 fallback
    border-right-color: $popsok-arrow-outer-color;
    &:after {
      content: " ";
      left: 1px;
      bottom: -$popsok-arrow-width;
      border-left-width: 0;
      border-right-color: $popsok-arrow-color;
    }
  }
  &.bottom > .arrow {
    left: 50%;
    margin-left: -$popsok-arrow-outer-width;
    border-top-width: 0;
    border-bottom-color: $popsok-arrow-outer-fallback-color; // IE8 fallback
    border-bottom-color: $popsok-arrow-outer-color;
    top: -$popsok-arrow-outer-width;
    &:after {
      content: " ";
      top: 1px;
      margin-left: -$popsok-arrow-width;
      border-top-width: 0;
      border-bottom-color: $popsok-arrow-color;
    }
  }

  &.left > .arrow {
    top: 50%;
    right: -$popsok-arrow-outer-width;
    margin-top: -$popsok-arrow-outer-width;
    border-right-width: 0;
    border-left-color: $popsok-arrow-outer-fallback-color; // IE8 fallback
    border-left-color: $popsok-arrow-outer-color;
    &:after {
      content: " ";
      right: 1px;
      border-right-width: 0;
      border-left-color: $popsok-arrow-color;
      bottom: -$popsok-arrow-width;
    }
  }
}
